<template>
  <v-container class="my-3">
    <PagePreLoader :loading="loading" />

    <v-sheet>
      <v-row no-gutters>
        <!--Vendor logo & info-->
        <v-col
          cols="12"
          lg="5"
        >
          <div class="vendor-profile">
            <div class="vendor-profile-left">
              <img
                :alt="vendor.name"
                :src="logo"
                class="logo"
                @error="useDefaultLogo"
              >
            </div>
            <div class="vendor-profile-right">
              <div class="vendor-info">
                <h3>{{ vendor.name }}</h3>
                <small>Member Since: {{ vendor.created_at | date }}</small>
                <small>Email: {{ vendor.email }}</small>
                <small>Phone: {{ vendor.cell }}</small>
                <small>{{ vendor.address }}</small>
              </div>
            </div>
          </div>
          <v-divider />
          <!--          <v-card-text>-->
          <!--<div class="vendor-social">-->
          <!--    <span class="group p-2">-->
          <!--      <a class="social-link text-decoration-none" href="#"><v-icon-->
          <!--        color="facebook">mdi-facebook</v-icon></a>-->
          <!--      <a class="social-link text-decoration-none" href="#"><v-icon-->
          <!--        color="messenger">mdi-facebook-messenger</v-icon></a>-->
          <!--      <a class="social-link text-decoration-none" href="#"><v-icon color="twitter">mdi-twitter</v-icon></a>-->
          <!--      <a class="social-link text-decoration-none" href="#"><v-icon color="skype">mdi-skype</v-icon></a>-->
          <!--      <a class="social-link text-decoration-none" href="#"><v-icon color="youtube">mdi-youtube</v-icon></a>-->
          <!--    </span>-->
          <!--</div>-->
          <!--          </v-card-text>-->
        </v-col>

        <!--banner and menus-->
        <v-col
          cols="12"
          lg="7"
        >
          <img
            :alt="vendor.name"
            :src="banner"
            class="cover v-responsive"
            style="max-height: 200px;"
            @error="useDefaultBanner"
          >
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col
          cols="12"
          class="py-0"
        >
          <!--          <v-divider />-->
          <v-tabs
            v-model="tab"
            centered
            color="secondary"
            show-arrows
          >
            <v-tabs-slider />
            <v-tab
              exact-path
              :to="{name: 'Vendor'}"
            >
              About
            </v-tab>
            <v-tab
              exact-path
              :to="{name : 'VendorProducts'}"
            >
              All Products
            </v-tab>
            <!--            <v-tab-->
            <!--              exact-path-->
            <!--              :to="{name : 'VendorFeatured'}"-->
            <!--            >-->
            <!--              Featured Products-->
            <!--            </v-tab>-->
            <!--            <v-tab disabled>-->
            <!--              <v-tooltip-->
            <!--                open-on-hover-->
            <!--                top-->
            <!--              >-->
            <!--                <template #activator="{ on, attrs }">-->
            <!--                  <div-->
            <!--                    style="color: #fff"-->
            <!--                    v-bind="attrs"-->
            <!--                    v-on="on"-->
            <!--                  >-->
            <!--                    Store Location-->
            <!--                  </div>-->
            <!--                </template>-->
            <!--                <span>Will Be Available Soon</span>-->
            <!--              </v-tooltip>-->
            <!--            </v-tab>-->
          </v-tabs>
        </v-col>
      </v-row>
    </v-sheet>

    <router-view />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PagePreLoader from '@/components/Utils/PagePreLoader';
import emptyShopLogo from '@/assets/images/emptyShopLogo.jpg';
import emptyShopCover from '@/assets/images/emptyShopCover.png';

export default {
  name: 'Vendor',
  components: { PagePreLoader },
  data: () => ({
    tab: '#tab-1',
    showPriceRange: false,

    logo: '',
    banner: '',
  }),
  computed: {

    ...mapGetters({
      vendor: 'vendor/currentVendor',
      loading: 'vendor/loading',
    }),
  },
  watch: {
    vendor(nv) {
      if (!nv) return;

      this.logo = nv.logo || emptyShopLogo;
      this.banner = nv.banner || emptyShopCover;
    }
  },

  methods: {

    ...mapActions({
      getVendor: 'vendor/getCurrentVendor'
    }),

    useDefaultLogo() {
      this.logo = emptyShopLogo;
    },

    useDefaultBanner() {
      this.banner = emptyShopCover;
    }

  },

  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('vendor/setCurrentVendor', {});
    this.$store.dispatch('vendor/setVendorProducts', []);
    return next();
  },

  async mounted() {
    let slug = this.$route.params.slug;
    await this.getVendor(slug);
    this.showPriceRange = true;
  }
};
</script>

<style lang="scss">
.v-slide-group__wrapper {
  background: #ececec !important;
  height: initial !important;
}
.vendor {
  &-profile {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 15px;

    &-left {
      .logo {
        max-width: 150px;
        border-radius: 2px;
      }
    }

    &-right {
      .vendor-info {
        display: flex;
        flex-direction: column;

        & small:not(:last-of-type) {
          margin-bottom: 2px;
        }
      }
    }
  }

  &-social {
    .group {
      display: flex;
      flex: 1;
      column-gap: 15px;
    }
  }
}

.cover {
  margin-bottom: 8px;
  border-radius: 2px;
}
</style>
